import React from "react";
import { Card } from "react-bootstrap";

const NeedPage = () => (
  <>
  <center><h1>Why Plant a NEI Church? </h1></center>
  <br/>
  <br/>
  <Card style={{ width: '80%' }}>
    <Card.Body>
      <Card.Title>The Need for the Gospel</Card.Title>
      <Card.Subtitle className="mb-2 text-muted">We are responding to God's commission and mandate to go and make disciples, to baptize, and teach everything Christ had taught</Card.Subtitle>
      <Card.Text>
        There are many immigrants from North-East India living in the Bay Area and Northern California. Many of the families are unchurched and unreached. This will be the first church planted in America for this demographic. God has called the NAGA Christians in the Bay Area to plant a new church for the people from North-East India. We are humbled and in awe that God is using a similar successful model as He did in the past- using people of the same culture.
      </Card.Text>
      <Card.Link href="https://www.biblegateway.com/passage/?search=Matthew+28:19&version=NKJV">God's Commission and Mandate</Card.Link>
    </Card.Body>
  </Card>
  <br/>
  <Card style={{ width: '80%' }}>
    <Card.Body>
    <Card.Title>The Need for Community</Card.Title>
      <Card.Subtitle className="mb-2 text-muted">The people from Northeast India are first-generation immigrants and there is a desire to create a loving community for the Second and Third generations</Card.Subtitle>
      <Card.Text>
        There is a great need to establish and nurture a caring community for the Northeast India Communities where they can safely express their rich cultures. The first generations can pass the rich culture, traditions, and identity to the second generation
      </Card.Text>
    </Card.Body>
  </Card>
  </>
);
export default NeedPage;