import React from "react";
import { Card, ListGroup } from "react-bootstrap";

const AboutPage = () => (
  <>
  <center><h1>Core Values</h1></center>
  <Card className="naga">
    <Card.Body>
      <Card.Text>
        <i>And they continued steadfastly in the apostles' doctrine and fellowship, in the breaking of bread, and in prayers.</i> <b>Act 42</b>
      </Card.Text>
    </Card.Body>
  </Card>
  <br/>
  <br/>
  <ListGroup variant="flush" numbered className="naga">
    <ListGroup.Item className="naga">Contextualizing the Gospel</ListGroup.Item>
    <ListGroup.Item className="naga">Compassionate Nurturing Community</ListGroup.Item>
    <ListGroup.Item className="naga">Contextual Worship</ListGroup.Item>
    <ListGroup.Item className="naga">Cultural Prayer</ListGroup.Item>
    <ListGroup.Item className="naga">Common Meal: Breaking of the bread</ListGroup.Item>
    <ListGroup.Item className="naga">Community Service</ListGroup.Item>
  </ListGroup>
  <br/>
  <br/>
  <p>Want to learn more? <a href="mailto:neichurch2022@gmail.com?subject=NEI%20Church">Contact us</a></p>
  <p>We are located at <a href="http://www.newlifebayarea.org/" target="_new">New Life Christian Fellowship</a></p>
  <br/>
  <a href="https://en.wikipedia.org/wiki/Northeast_India" target="_new">About North East India</a>
  </>
);
export default AboutPage;